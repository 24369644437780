
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.surveys-detail {
  &__actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__action {
    font-weight: 500;
    &--function {
      color: $color-black;
    }
  }
}

.surveys-detail-table {
  &__head {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: $base-gutter / 1.5;
    padding: 0px 27px 8px;
  }
  &__head-item {
    color: $color-black-op-50;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: $base-gutter / 1.5;
  }
  &__actions {
    text-align: center;
  }
  &__item {
    position: relative;

    &:hover {
      box-shadow: 0px 0px 20px rgba(28, 41, 61, 0.05), 0px 12px 20px rgba(28, 41, 61, 0.06);
      transition: box-shadow $base-animation;
    }
  }
  &__item-inner {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 10px 27px;
    background-color: $color-white;
    border-radius: $base-border-radius;
    font-weight: 500;
  }
  &__hat {
    position: absolute;
    top: 0;
    left: -8px;
    height: 32px;
    width: 24px;
    border-radius: $base-border-radius;
    z-index: 1;
    opacity: .25;
  }
  &__theme {
    position: relative;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  &__priority {
    position: absolute;
    top: 2px;
    left: -16px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__action {
    color: $color-black;
    font-weight: 500;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;

    .esmp-user-avatar {
      flex-shrink: 0;
    }
  }
}

.surveys-detail-inner {
  display: flex;
  gap: $gap;
  padding-bottom: 80px;
  &__main {
    background-color: $color-white;
    width: 70%;
    border-radius: $base-border-radius * 2;
    padding: 32px;
  }
  &__sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    width: calc(100% - #{$sidebar-width});
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $color-white;
    padding: $gap / 2 $gap;
    &--wide {
      width: calc(100% - #{$sidebar-collapse-width});
    }
  }
  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $base-gutter;
  }
  &__info {
    display: flex;
    align-items: center;
    gap: $base-gutter / 1.5;
    font-weight: 500;
  }
  &__label {
    color: $color-grayscale-40;

    &:not(:last-child) {
      padding-right: $base-gutter / 1.5;
      position: relative;

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-grayscale-40;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -1px;
      }
    }
  }
  &__actions {
    display: flex;
    gap: $base-gutter / 1.5;

    .esmp-button {
      border-color: $color-grayscale-10;
      height: 24px;
      font-weight: 500;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: $base-gutter;
    word-break: break-word;
  }
  &__subtitle {
    font-weight: 700;
    margin-bottom: $base-gutter * 2;
  }
  &__list-box {
    border: 1px solid $color-grayscale-10;
    border-radius: $base-border-radius;
    padding: $base-gutter * 2;
    &--disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  &__description {
    color: $color-black-op-70;
    margin-bottom: $base-gutter * 2;
    word-break: break-word;
  }
  &__results {
    display: flex;
  }
  &__result {
    display: flex;
    align-items: center;
    margin-right: $base-gutter * 2;
    font-weight: 700;

    .esmp-svg-icon {
      width: 15px;
      margin-left: $base-gutter / 3;
    }
  }
  &__rate {
    .esmp-svg-icon {
      width: 24px;
    }
    &--up {
      .esmp-svg-icon {
        color: $color-green-status;
      }
    }
    &--down {
      .esmp-svg-icon {
        color: $color-red;
      }
    }
  }
  &__history {
    color: $color-black;

    .esmp-svg-icon {
      width: 15px;
    }
  }
  &__box {
    padding: 8px;
    border-radius: $base-border-radius * 1.5;
    border: 1px solid $color-grayscale-10;
  }
  &__common {
    padding: 12px 16px;
    border-radius: $base-border-radius * 1.5;
    background-color: $color-grayscale-05;
    font-weight: 500;
  }
  &__item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: $base-gutter / 1.4;
    }
  }
  &__col {
    &--wide {
      width: 100%;
    }
    &--small {
      width: 8%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: $base-gutter;

      .esmp-svg-icon {
        width: 15px;
      }
    }
  }
  &__name {
    color: $color-black-op-50;
  }
  &__list {
    padding: 12px 16px;
  }
  &__head {
    color: $color-black-op-50;
  }
  &__body {
    margin-bottom: $base-gutter * 1.4;
  }
  &__block {
    background-color: $color-white;
    border-radius: $base-border-radius * 2;
    padding: 16px;
  }
  &__title {
    font-weight: 700;
    margin-bottom: $base-gutter * 1.4;
  }
  &__question {
    padding: $base-gutter * 1.4 0;
    border-bottom: 1px solid $color-black-op-10;
  }
  &__question-number {
    color: $color-black-op-50;
  }
  &__question-text {
    margin-bottom: $base-gutter / 1.5;
  }
  &__question-label {
    color: $color-primary-1-day;
  }
  .base-date {
    font-size: 14px;
    line-height: 20px;
  }
}
